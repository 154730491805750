import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: localStorage.token ? localStorage.token : "",
    deptId: "", // 支付的deptId
    isFullScreen: false,
  },
  mutations: {
    getToken: (state, token ) => {
      state.token = token 
    },
    getDeptId: ( state, id ) => {
      state.deptId = id 
    },
    getFullScreenStatus: ( state, bool) => {
      state.isFullScreen = bool
    }
  },
  getters: {
    token: state => state.token,
    deptId: state => state.deptId,
    isFullScreen: state => state.isFullScreen
  },

  modules: {
  }
})
