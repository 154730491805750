import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'animate.css';
import 'hover.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { serverUrl } from './config';
import axios from './http';
import echarts from 'echarts';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.use(ElementUI);

Vue.use(Viewer);
// 组件设置属性
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
});

Vue.prototype.$serverUrl = serverUrl;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
// echarts组件
Vue.prototype.$echarts = echarts;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
