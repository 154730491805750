import axios from 'axios';
import { JSEncrypt } from 'jsencrypt';
var randomString = (params) => {
  return (
    'tms' +
    params +
    parseFloat(Math.random())
      .toFixed(5)
      .toString()
      .split('.')[1]
  );
};
function getRSApass(val) {
  if (!val) {
    return;
  }
  let jse = new JSEncrypt();
  //公钥
  var publicString =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0x8k+NCf0CnP6qiA7v96uGyAH1fgJqNqAOgKHB5HakXUA6ANOQ7XMq+7pBSVdm1Gv841avjcPm0OeQCuSb0ObsqzL4SAGFk+Y0XRpfMkrFEUo9avwnRewOpM7YmIrCiSpiHv+5MtDeXhf16ZL6XTBOYV0K3Dtb6jhd5RYWG+nh7DUUxSS03s467vcb+4aSRbXGJN/Voc/1I6Wly24UBJRtd3kPS9PGUD3ZlWlT7LPxf+VU0s1YRYvjNT7A9JwlpRnfmz/9H26xJWvFgSFJFRPXTKPs8Do3oHb0fAdOuL3yqLCup7bKqD53Ug+dgI81pjkfPoOLUxwdGHvsH4ntLFCQIDAQAB';
  jse.setPublicKey(publicString);
  // 加密内容
  let encrypted = jse.encrypt(val);
  return encrypted;
}

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.token;
    if (token) {
      config.headers.authorization = token;
    }

    var timestamps = new Date().format('yyyyMMddhhmmss');
    let nonce = randomString(timestamps);
    let timeStamp = new Date().getTime();
    let platCompanyId = 'dc15deaa0bc54034872d84068f9da95a';
    let TmsSignature = timeStamp + '$' + nonce + '$' + platCompanyId;
    // console.log(TmsSignature);
    TmsSignature = getRSApass(TmsSignature);

    config.headers['Tms-Signature'] = TmsSignature;
    config.headers['Tms-Timestamps'] = timeStamp;
    config.headers['Tms-Nonce'] = nonce;
    config.headers['Tms-Serial'] = platCompanyId;
    config.headers['platform'] = platCompanyId;
    config.headers['sy-platform'] = platCompanyId;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
