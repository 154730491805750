import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/', //首页
    name: 'newHome',
    component: () => import('../views/newHome.vue'),
  },
  {
    path: '/AboutUs', //关于我们
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/NewsBulletin', //新闻公告
    name: 'NewsBulletin',
    component: () => import('../views/NewsBulletin.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/Particulars', //新闻详情
    name: 'NewsBulletinParticulars',
    component: () => import('../views/Particulars.vue'),
  },
  {
    path: '/tradingInformation', //交易资讯
    name: 'tradingInformation',
    component: () => import('../views/tradingInformation.vue'),
    meta: { keepAlive: true },
  },
  {
    path: '/Particulars', //交易资讯详情
    name: 'tradingInformationParticulars',
    component: () => import('../views/Particulars.vue'),
  },
  {
    path: '/AmpleSupply', //货源大厅
    name: 'AmpleSupply',
    component: () => import('../views/AmpleSupply.vue'),
  },
  {
    path: '/shippingAbility', //货源大厅
    name: 'shippingAbility',
    component: () => import('../views/shippingAbility.vue'),
  },
  {
    // 配置404路由路径
    path: '*',
    name: 'Notfound',
    component: () => import('../views/Notfound.vue'),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
